import React from "react";
import styled from "styled-components";
import UpperAnnouncement from "../components/UpperAnnouncement.jsx";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import Banner from "../components/Banner.jsx";
import LowerAnnouncement from "../components/LowerAnnouncement.jsx";
import Slider from "../components/Slider.jsx";
import Items from "../components/Items.jsx";
import WelcomeBanner from "../components/WelcomeBanner.jsx";
import About from "../components/About.jsx";
import SwiperProduct from "../components/SwiperProduct.jsx";

const MainContainer = styled.div`
  background-color: var(--almost-white);
`;

const Home = () => {
  return (
    <div>
      <MainContainer>
        <UpperAnnouncement />
        <Navbar />
        <LowerAnnouncement />
        <WelcomeBanner />

        <SwiperProduct
          data={{
            title: "HIM",
            description:
              " Discover your signature fragrance from our exclusive collection for Men. With just a few spritzes, a well- chosen perfume becomes an integral part of a man's identity, enhancing his confidence, charm, and allure. It is a subtle yet powerful expression of his individuality, leaving a lasting impression wherever he goes",
          }}
        />
        <SwiperProduct
          data={{
            title: "HER",
            description:
              " Discover your signature fragrance from our exclusive collection for Women. Each perfume tells a unique story , reflecting the personality, style, and mood of the woman who wears it . Whether she prefers the timeless elegance of a floral bouquet, the mysterious allure of oriental spices , the refreshing crispness of citrus fruits, or the comforting warmth of vanilla and amber, there is a fragrance to suit every taste and occasion",
          }}
        />
        <Slider />

        {/* <Items /> */}
        <Banner />
        <About />
        <Footer />
      </MainContainer>
    </div>
  );
};

export default Home;
