import { publicRequest, protectedRequest } from "../axiosMethod";

export const login = async (dispatch, user) => {
  dispatch({ type: "loginStart" });
  try {
    const res = await publicRequest.post("/login", user);
    console.log("Login response:", res.data.data); // Log the response data

    const token = res.data.data.token;
    console.log("Token:", token); // Log the extracted token

    localStorage.setItem("token", token); // Store the token in local storage

    // Make another API request with the token
    const secondRes = await protectedRequest.get("/login");

    // Log the response of the second API request
    console.log("Second API response:", secondRes.data);
    dispatch({ type: "loginSuccess", payload: secondRes.data.data });
  } catch (err) {
    dispatch({ type: "loginFailure" });
  }
};
