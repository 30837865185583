import React from "react";
import styled from "styled-components";
import bannerImg from "../assets/btl_img/Perfume_poster1.jpg";
import bannerCard from "../assets/btl_img/Perfume_poster2.jpg";
import { Link } from "react-router-dom";

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: initial;
  background-color: rgba(var(--color-overlay), var(--color-overlay-opacity));
  margin: 20px;
`;

const BannerMedia = styled.div`
  width: 100%;
  position: relative;
  //   z-index: -1;

  /* Additional styles can be added here */
  @media screen and (max-width: 767px) {
    // height: 300px;
    object-fit: cover;
  }
`;

const BannerContent = styled.div`
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 0;
`;

const BannerBox = styled.div`
  border: 0;
  position: relative;
  z-index: 2;
  height: fit-content;
  align-items: center;
  width: 100%;
  text-align: center;

  /* Additional styles can be added here */
`;

const BannerHeading = styled.h2`
  margin-bottom: 0;
  line-height: 1.2;
  letter-spacing: 0.02em;

  /* Additional styles can be added here */
`;

const BannerText = styled.div`
  /* Additional styles can be added here */
`;

const BannerButton = styled.a`
  /* Additional styles can be added here */
`;

const BannerImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;

  @media screen and (max-width: 767px) {
  }
`;

const Button = styled.button`
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: #dfd9d1;
  color: #000000;
  // border-radius: 4px;
  padding: 10px 30px;
  cursor: pointer;
  width: 200px;
  transition: 0.3s ease;
  font-family: glacial indifference;

  &:hover {
    background-color: #000;
    color: #fff;

    box-shadow: rgb(38, 57, 77) 0px 20px 20px -10px;
  }
`;
const WelcomeBanner = () => {
  return (
    <>
      <BannerContainer>
        <BannerMedia>
          <BannerImage src={bannerImg} alt="Banner Image" />
        </BannerMedia>
      </BannerContainer>
      <BannerContainer>
        <BannerMedia>
          <BannerImage src={bannerCard} alt="Banner Image" />

          <BannerContent>
            <BannerBox>
              <BannerText className="banner__text typeset2 rte">
                <p>WELCOME</p>
              </BannerText>
              <BannerHeading className="banner__heading h1">
                EXPERIENCE NATURE'S ESSENCE
              </BannerHeading>
              <BannerText className="banner__text typeset2 rte py-2">
                <p>Indulge in luxury fragrances that define elegance</p>
              </BannerText>

              <div className="text-center">
                <Link
                  to="/register"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button>EXPLORE NOW</Button>
                </Link>
              </div>
            </BannerBox>
          </BannerContent>
        </BannerMedia>
      </BannerContainer>
    </>
  );
};

export default WelcomeBanner;
