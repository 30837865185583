import axios from "axios";

const BASE_URL = "https://api.mygaia.in/";

const publicRequest = axios.create({
  baseURL: BASE_URL,
});

const protectedRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // Other default headers can be added here
  },
});

// Add interceptor to include bearer token in the headers
protectedRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Fetch token from local storage or wherever it's stored
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { publicRequest, protectedRequest };
