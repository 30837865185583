import React from "react";
import lipstick1 from "../assets/Items/lipstick1.jpg";
import foundation from "../assets/Items/foundation.jpg";
import mascara from "../assets/Items/mascara.jpg";
import eyeliner from "../assets/Items/eyeliner.png";
import bronzer from "../assets/Items/bronzer.jpg";
import blush from "../assets/Items/blush.jpg";
import { productsData } from "../data";
import styled from "styled-components";
import { medium } from "../responsive";
import { Link } from "react-router-dom";

//sytled components
const AboutContainer = styled.div`
  margin: 3rem 0rem;
  background-color: #fff;
  padding: 1rem;
`;
const AbboutTitle = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 550;
  letter-spacing: 2.5px;
  margin-top: -5px;
`;
const AboutTxt = styled.div`
  margin-top: -5px;
  color: #5e5e5e;
  font-size: 14px;
  text-align: center;
  padding: 10px;
`;
const OuterContainer = styled.div`
  margin: 2rem 10rem;
  /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  ${medium({ margin: "2rem 0" })}
`;

const HideCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  color: white;
  display: none;
`;

const InnerContainer = styled.div`
  background-color: red;
  height: 25rem;
  width: 20rem;
  overflow: hidden;
  position: relative;
  transition: all 0.4s linear;

  &:hover {
    /* box-shadow: 1px 1px 5px 5px #141314; */
    box-shadow: rgba(8, 8, 8, 0.4) 0px 5px, rgba(14, 12, 13, 0.3) 0px 10px,
      rgba(19, 14, 17, 0.2) 0px 15px, rgba(41, 17, 32, 0.1) 0px 20px,
      rgba(27, 11, 21, 0.05) 0px 25px;
  }

  &:hover ${HideCard} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: all 1s linear;
  }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Button = styled.button`
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: #dfd9d1;
  color: #000000;
  // border-radius: 4px;
  padding: 10px 30px;
  cursor: pointer;
  width: 300px;
  transition: 0.3s ease;
  font-family: glacial indifference;

  &:hover {
    background-color: #000;
    color: #fff;

    box-shadow: rgb(38, 57, 77) 0px 20px 20px -10px;
  }
`;

const About = () => {
  return (
    <AboutContainer>
      <AbboutTitle>ABOUT GAIA</AbboutTitle>
      <AboutTxt>
        <p>
          {" "}
          Gaia is your gateway to luxury fragrances. All our fragrances are Eau
          De Parfums formulated with 18% - 20% fragrance oil concentration,
          ensuring perfumes that linger like a treasured memory. Every fragrance
          we create is a blend of unique notes, meticulously crafted with the
          highest quality formulations. Join us on a Gaia journey that takes
          inspiration from our global travels and the beauty of nature.
        </p>
      </AboutTxt>
      {/* <div className="text-center">
        <Link
          to="/register"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Button>LEARN MORE</Button>
        </Link>
      </div> */}
    </AboutContainer>
  );
};

export default About;
