import React, { useState, useEffect } from "react";
import styled from "styled-components";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { TailSpin } from "react-loader-spinner";
import { publicRequest } from "../axiosMethod";
import "../pages/ProductList.css";
import BlueTick from "../assets/Icons/bule_tick.png";

//styled comp

const Container = styled.div`
  padding: 15px;
`;
const OuterCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const Icon = styled.img`
  width: 20px;
  height: auto;
`;
const Card = styled.div`
  backgroundColor: 'white',
cursor: 'pointer',
padding: '10px 5px',
gap: '1rem',
display: 'flex',
flexWrap: 'wrap',
flexDirection: 'column',
justifyContent: 'center',
alignItems: 'center',
gap: '0.5rem',
transition: 'all 0.2s linear',
boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
borderRadius: '8px'

  &:hover {
    transform: scale(0.95);
    box-shadow: rgb(38, 57, 77) 0px 20px 10px -10px;
  }
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  // padding: 15px;
  /* margin-top: 15px; */
`;

const PriceGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
`;

const Price = styled.p`
  font-size: 18px;
  color: #000;
  text-align: left;
  margin: 0;

  &.old_price {
    text-decoration: line-through;
    line-height: 0.9375rem;
    font-size: 0.75rem;
    font-weight: 400;
  }

  &.newPrice {
    font-weight: 800;
    line-height: 0.9375rem;
    font-size: 0.75rem;
  }
`;

const Products = ({ filters }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const location = useLocation();

  const getProducts = async (filters) => {
    try {
      let res = await publicRequest.get("products/", { params: filters });
      setProducts(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    let newFilters = { ...filters };
    for (let key in newFilters) {
      console.log(key, newFilters[key]);
      if (newFilters[key].length === 0) {
        delete newFilters[key];
      } else if (Array.isArray(newFilters[key])) {
        newFilters[key] = newFilters[key].join(",");
      }
    }
    console.log(newFilters);
    getProducts(newFilters);
  }, [filters, location]);

  return (
    <Container>
      {loading ? (
        <div className="d-flex justify-content-center">
          <TailSpin type="TailSpin" color="#25283D" height={100} width={100} />
        </div>
      ) : (
        <OuterCard>
          {products.map((item) => {
            return (
              <Link
                to={`/product/${item.product_id}`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "5px",
                  float: "left",
                  display: "flex",
                  justifyContent: "flex-start",
                  background: "#e8e4e4",
                }}
                className="product-link m-1"
              >
                <Card>
                  <div>
                    <img
                      className="mt-0"
                      src={`${process.env.REACT_APP_BASE_IMG_URL}${item.main_image}`}
                      alt="product"
                      style={{
                        objectFit: "cover",
                        display: "inline-block",
                        maxWidth: "100%",
                        // height: "200px",
                      }}
                      // width="265px"
                      // height="185px"
                    />
                  </div>
                  <div>
                    <b>{item.product_name}</b>
                  </div>
                  <div
                    style={{
                      fontSize: "0.75rem",
                    }}
                  >
                    {item.brand_name}
                  </div>

                  <Action>
                    <PriceGroup>
                      <Price className="old_price"> ₹ 1499</Price>
                      <Price className="newPrice">₹ {item.price}</Price>
                    </PriceGroup>
                  </Action>
                  <div
                    className="d-flex align-items-center flex-wrap"
                    style={{ fontSize: "small" }}
                  >
                    <StarRatings
                      rating={2}
                      starRatedColor="gold"
                      numberOfStars={1}
                      name="rating"
                      starDimension="20px"
                      starSpacing="2px"
                    />
                    {"  "}
                    4.4 | <Icon src={BlueTick} alt="Logo Image" /> ( Reviews 256
                    )
                  </div>
                </Card>
              </Link>
            );
          })}
        </OuterCard>
      )}
    </Container>
  );
};

export default Products;
