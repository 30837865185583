import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { publicRequest } from "../axiosMethod";
import lipstick1 from "../assets/Items/Image1.png";
import foundation from "../assets/Items/Image2.png";
import mascara from "../assets/Items/Image3.png";
import eyeliner from "../assets/Items/Image4.png";
import bronzer from "../assets/Items/Image5.png";
import blush from "../assets/Items/Image6.png";
import { productsData } from "../data";
import { medium } from "../responsive";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./../pages/ProductList.css";

// import required modules
import { EffectCards } from "swiper/modules";

import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper/modules";
import Card from "./Card";

const ItemContainer = styled.div`
  margin-top: 3rem;
`;
const Button = styled.button`
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: #dfd9d1;
  color: #000000;
  // border-radius: 4px;
  padding: 10px 30px;
  cursor: pointer;
  width: 300px;
  transition: 0.3s ease;
  font-family: glacial indifference;

  &:hover {
    background-color: #000;
    color: #fff;

    box-shadow: rgb(38, 57, 77) 0px 20px 20px -10px;
  }
`;

//sytled components
const ItemTitle = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 550;
  letter-spacing: 2.5px;
  margin-top: -5px;
`;
const OuterContainer = styled.div`
  margin: 2rem 0rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  width: inherit;
  ${medium({ margin: "2rem 0" })}
`;
const LaptopView = styled.div``;

const HideCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  color: white;
  display: none;
`;

const InnerContainer = styled.div`
  background-color: red;
  height: 25rem;
  width: 20rem;
  overflow: hidden;
  position: relative;
  transition: all 0.4s linear;

  &:hover {
    /* box-shadow: 1px 1px 5px 5px #141314; */
    box-shadow: rgba(8, 8, 8, 0.4) 0px 5px, rgba(14, 12, 13, 0.3) 0px 10px,
      rgba(19, 14, 17, 0.2) 0px 15px, rgba(41, 17, 32, 0.1) 0px 20px,
      rgba(27, 11, 21, 0.05) 0px 25px;
  }

  &:hover ${HideCard} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: all 1s linear;
  }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const AboutTxt = styled.div`
  margin-top: -5px;
  color: #5e5e5e;
  font-size: 14px;
  text-align: center;
  padding: 10px;
`;

export default function SwiperProduct(props) {
  let i = -1;
  let itemArray = [lipstick1, foundation, mascara, eyeliner, bronzer, blush];
  let y = -1;

  const [swiperRef, setSwiperRef] = useState(null);

  const [screenwidth, setScreenwidth] = useState(5); // Initial value, can be adjusted based on your design requirements
  const [slidesPerView, setSlidesPerView] = useState(1); // Initial value, can be adjusted based on your design requirements

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const getProducts = async () => {
    try {
      let res = await publicRequest.get("products/");
      console.log(res.data);
      setProducts(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProducts();
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setScreenwidth(screenWidth);
      const minCardWidth = 450; // Minimum width per card required
      const minSlides = Math.ceil(screenWidth / minCardWidth); // Calculate minimum slides required based on screen width and minimum card width
      setSlidesPerView(minSlides);
      console.log(minSlides);
    };

    handleResize(); // Call once on component mount to set initial value

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ItemContainer>
        <ItemTitle>{props.data.title}</ItemTitle>
        <AboutTxt style={{ textAlign: "center" }}>
          <p>{props.data.description}</p>
        </AboutTxt>

        <OuterContainer>
          <Swiper
            slidesPerView={
              slidesPerView > 4 ? "4" : slidesPerView === 1 ? "1" : "3"
            }
            centeredSlides={false}
            slidesPerGroupSkip={1}
            grabCursor={false}
            keyboard={{
              enabled: false,
            }}
            scrollbar={false}
            navigation={true}
            modules={[Keyboard, Scrollbar, Navigation]}
            className="mySwiper"
          >
            {loading ? (
              <div className="d-flex justify-content-center">
                <TailSpin
                  type="TailSpin"
                  color="#25283D"
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              products.map((item, index) => (
                <InnerContainer key={item.id}>
                  <SwiperSlide>
                    <Link
                      to={`/product/${item.product_id}`}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        padding: "5px",
                        float: "left",
                        display: "flex",
                        justifyContent: "flex-start",
                        background: "#e8e4e4",
                      }}
                    >
                      <Card
                        title={item.product_name}
                        images={`${process.env.REACT_APP_BASE_IMG_URL}${item.main_image}`}
                        old_price={item.price}
                        newPrice={item.discount_price}
                        dollar="₹"
                        alt="batman"
                      />
                    </Link>
                  </SwiperSlide>
                </InnerContainer>
              ))
            )}
          </Swiper>
        </OuterContainer>
      </ItemContainer>
    </>
  );
}
