import { ErrorMessage, Field, Form, Formik } from "formik";
import background from "../assets/login/Gaia_login.png";
import logoImg from "../assets/logo/Vilavee_logo.png";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { useHistory } from "react-router";
import * as YUP from "yup";
import { large } from "../responsive";
import axios from "axios";
import Navbar from "../components/Navbar";

//styled comp
const Containerbanner = styled.div`
  background-image: url(${background});
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  background-position: 55% 30%;
  overflow: scroll;
`;
const Container = styled.div`
  ${large({
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  })}
`;
const OuterContainer = styled.div`
  // margin-top: 3rem;
  height: 25rem;
  width: 25rem;
  background-color: transparent;
  position: relative;
  top: 23%;
  left: 55%;
  box-sizing: border-box;

  ${large({ position: "relative", top: "0", left: "0" })}
`;

const Brand = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  color: #444;
`;

const LogoImage = styled.img`
  width: 185px;
  height: auto;
  @media screen and (max-width: 767px) {
    //  width: auto;
    height: 100%;
  }
`;
const FormContainer = styled.div`
  background-color: white;
  padding: 14px;
  margin: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
const Input = styled(Field)`
  height: 2rem;
  margin: 1rem 0;
`;
const Error = styled.div`
  color: red;
  font-size: 0.8rem;
  margin: 0;
`;
const Para = styled.p`
  cursor: pointer;
  display: inline-block;
  color: #000000;
  font-size: 14px;
  font-family: Agapey;
  word-spacing: 1.4px;
`;

const Button = styled.button`
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: #898c99;
  color: white;
  // border-radius: 4px;
  padding: 10px 30px;
  cursor: pointer;
  width: -webkit-fill-available;
  transition: 0.3s ease;
  font-family: glacial indifference;

  &:hover {
    background-color: #000;
    color: #fff;

    box-shadow: rgb(38, 57, 77) 0px 20px 20px -10px;
  }
`;

const Register = () => {
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);

  //schema
  const registerSchema = YUP.object().shape({
    name: YUP.string()
      .required("Please Enter name")
      .min(6, "name length should be more than 5"),

    email: YUP.string().required("Please Enter Email").email(),
    password: YUP.string()
      .required("Please Enter Password")
      .min(6, "Password length should be more than 5"),
    confirmPassword: YUP.string()
      .required()
      .oneOf([YUP.ref("password"), null], "Password Should Match"),
  });
  return (
    <Containerbanner>
      <Navbar />
      <Container>
        <OuterContainer>
          <Brand>
            <LogoImage src={logoImg} alt="Logo Image" />
          </Brand>

          <FormContainer>
            {/* <div
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                fontWeight: "700",
                fontFamily: "Arial, Helvetica, sans-serif",
                padding: "30px 0px",
              }}
            >
              MY ACCOUNT
            </div> */}

            <Formik
              initialValues={{
                gender: "",
                name: "",
                phone: "",
                email: "",
                dob: "",
                password: "",
                confirmPassword: "",
                acceptedTerms: false,
              }}
              validationSchema={registerSchema}
              onSubmit={async (values, { resetForm }) => {
                console.log("in submit register");
                setLoading(true);
                console.log(values);
                try {
                  const { confirmPassword, ...other } = values;
                  //   const res = await axios.post(
                  //     `https://muthu-ecommerce-server.herokuapp.com/auth/register`,
                  //     other
                  //   );
                  //   console.log(res);
                  setInfo(
                    "User created Successfully,Please Login with your Email/Password "
                  );
                  setLoading(false);
                  resetForm(); //clear inputs
                } catch (error) {
                  setInfo("Email already exists");
                  setLoading(false);
                }

                // setLoading(true);
              }}
            >
              {() => {
                return (
                  <Form>
                    <div
                      id="genderGroup"
                      role="group"
                      aria-labelledby="genderGroup"
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        color: "#000000",
                        fontSize: "14px",
                        fontFamily: "Agapey",
                        wordSpacing: "1.4px",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Field type="radio" name="gender" value="male" />
                        <span>Male</span>
                      </label>
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Field type="radio" name="gender" value="female" />
                        <span>Female</span>
                      </label>
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Field type="radio" name="gender" value="other" />
                        <span>Other</span>
                      </label>
                    </div>
                    <div className="form-group">
                      <Input
                        type="text"
                        placeholder="FULL NAME*"
                        className="form-control"
                        id="name"
                        name="name"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          borderBottom: "2px solid #898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                        }}
                      />
                      <Error>
                        <ErrorMessage name="name" />
                      </Error>
                    </div>
                    <div className="form-group">
                      <Input
                        type="number"
                        placeholder="PHONE*"
                        className="form-control"
                        id="phone"
                        name="phone"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          borderBottom: "2px solid #898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                        }}
                      />
                      <Error>
                        <ErrorMessage name="phone" />
                      </Error>
                    </div>
                    <div className="form-group">
                      <label
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          color: "#898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                        }}
                      >
                        DOB*
                      </label>
                      <Input
                        placeholder="DOB*"
                        className="form-control"
                        id="dob"
                        name="dob"
                        type="date"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          borderBottom: "2px solid #898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                          margin: "0",
                        }}
                      />
                      <Error>
                        <ErrorMessage name="dob" />
                      </Error>
                    </div>
                    <div className="form-group">
                      <Input
                        type="text"
                        placeholder="Email*"
                        className="form-control"
                        id="email"
                        name="email"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          borderBottom: "2px solid #898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                        }}
                      />
                      <Error>
                        <ErrorMessage name="email" />
                      </Error>
                    </div>
                    <div className="form-group">
                      <Input
                        type="password"
                        placeholder="PASSWORD*"
                        className="form-control"
                        id="password"
                        name="password"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          borderBottom: "2px solid #898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                        }}
                      />
                      <Error>
                        <ErrorMessage name="password" />
                      </Error>
                    </div>
                    <div className="form-group">
                      <Input
                        type="password"
                        placeholder="CONFIRM PASSWORD*"
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          borderBottom: "2px solid #898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                        }}
                      />
                      <Error>
                        <ErrorMessage name="confirmPassword" />
                      </Error>
                    </div>
                    <div className="form-group">
                      <label
                        className="text-center"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                        }}
                      >
                        <Field
                          name="acceptedTerms"
                          id="acceptedTerms"
                          type="checkbox"
                        />
                        <span
                          style={{
                            cursor: "pointer",
                            display: "inline-block",
                            color: "#000000",
                            fontSize: "14px",
                            fontFamily: "Agapey",
                            wordSpacing: "1.4px",
                          }}
                        >
                          I have read and agreed on Terms and Conditions
                        </span>
                      </label>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {loading && (
                        <>
                          <TailSpin
                            type="Bars"
                            color="#adb4ec"
                            height={30}
                            width={30}
                          />
                        </>
                      )}
                    </div>
                    <div className="text-center">
                      <Button type="submit">CREATE ACCOUNT</Button>
                    </div>
                    <div>
                      <p style={{ color: "orange" }}>{info}</p>
                    </div>
                    <div className="text-center">
                      <Link to="/login">
                        <Para>Back to login</Para>
                      </Link>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </FormContainer>
        </OuterContainer>
      </Container>
    </Containerbanner>
  );
};

export default Register;
