import React from "react";
import styled from "styled-components";

// Styled Components
const StyledCard = styled.div`
  transition: ease all 0.3s;
  width: 250px;
  background-color: #fff;
  margin: auto;
`;

const CardImage = styled.div`
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: absolute;
  bottom: calc(100% - 200px);
  width: 100%;
  height: 332px;
  background-image: ${(props) => `url(${props.bgImage})`};
  transition: ease all 0.3s;
`;

const Wrapper = styled.div`
  margin: auto;
  padding-top: 200px;
  position: relative;
  box-shadow: 0px 20px 20px 4px rgba(25, 25, 25, 0.1);
  transition: ease all 0.3s;

  &:hover {
    // transform: translateY(-10px);
    background-color: ${(props) => props.theme};

    ${CardImage} {
      height: 350px;
    }
  }
`;

const ColorBg = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: ${(props) => props.theme};
`;

const Heart = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  width: 25px;
  height: 25px;
  color: #fff;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: block;
  padding: 20px 10px 5px 10px;
  font-family: "Roboto";
  color: #666;
`;

const Title = styled.h1`
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0;
  font-family: BauerBodoni-roman, serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const SetType = styled.p`
  width: 100%;
  text-align: center;
  margin: 0.125rem 0 0;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 0;
  font-family: Post Grotesk, sans-serif;
  font-weight: 400;
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  // padding: 15px;
  /* margin-top: 15px; */
`;

const PriceGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
`;

const Price = styled.p`
  font-size: 18px;
  color: #000;
  text-align: left;

  &.old_price {
    text-decoration: line-through;
    line-height: 0.9375rem;
    font-size: 0.75rem;
    font-weight: 400;
  }

  &.newPrice {
    font-weight: 800;
    line-height: 0.9375rem;
    font-size: 0.75rem;
  }
`;

const Cart = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
  cursor: pointer;
`;

export default function Card({
  alt,
  images,
  title,
  old_price,
  newPrice,
  dollar,
  exp_date,
}) {
  return (
    <StyledCard>
      <Wrapper>
        <ColorBg className={`color_bg ${alt}`} />
        <CardImage bgImage={images} />
        <Heart>{/* SVG here */}</Heart>
        <CardInfo>
          <Title>{title}</Title>
          <SetType>{exp_date}</SetType>
          <Action>
            <PriceGroup>
              <Price className="old_price">
                {dollar} {old_price}
              </Price>
              <Price className="newPrice">
                {dollar} {newPrice}
              </Price>
            </PriceGroup>
          </Action>
        </CardInfo>
      </Wrapper>
    </StyledCard>
  );
}
