import { ErrorMessage, Field, Form, Formik } from "formik";
import background from "../assets/login/Gaia_login.png";
import logoImg from "../assets/logo/Vilavee_logo.png";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { login } from "../redux/apiCalls";
import styled from "styled-components";
import { useNavigate } from "react-router";
import * as YUP from "yup";
import { large } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";

//styled comp
const Containerbanner = styled.div`
  background-image: url(${background});
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  background-position: 55% 30%;
`;
const Container = styled.div`
  ${large({
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  })}
`;
const OuterContainer = styled.div`
  // margin-top: 3rem;
  height: 25rem;
  width: 25rem;
  background-color: transparent;
  position: relative;
  top: 23%;
  left: 55%;
  box-sizing: border-box;

  ${large({ position: "relative", top: "0", left: "0" })}
`;

const Brand = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  color: #444;
`;

const LogoImage = styled.img`
  width: 185px;
  height: auto;
  @media screen and (max-width: 767px) {
    //  width: auto;
    height: 100%;
  }
`;
const FormContainer = styled.div`
  background-color: white;
  padding: 14px;
  margin: 1rem;
  margin-top: 5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
const Input = styled(Field)`
  height: 2rem;
  margin: 1rem 0;
`;
const Error = styled.div`
  color: red;
  font-size: 0.8rem;
  margin: 0;
`;
const Para = styled.p`
  cursor: pointer;
  display: inline-block;
  color: #000000;
  font-size: 14px;
  font-family: Agapey;
  word-spacing: 1.4px;
`;
const Forget = styled.div`
  text-align: end;
`;
const Button = styled.button`
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: #898c99;
  color: white;
  // border-radius: 4px;
  padding: 10px 30px;
  cursor: pointer;
  width: -webkit-fill-available;
  transition: 0.3s ease;
  font-family: glacial indifference;

  &:hover {
    background-color: #000;
    color: #fff;

    box-shadow: rgb(38, 57, 77) 0px 20px 20px -10px;
  }
`;
const Invalid = styled.div`
  color: red;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const Login = () => {
  const history = useNavigate();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  if (user.currentUser) {
    console.log(user.currentUser.isAdmin); // is admin or not ?
    if (user.currentUser.isAdmin !== true) history("/adminHome");
    else history("/");
  }

  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);
  const [inputError, setInputError] = useState(false);

  //schema
  const signInSchema = YUP.object().shape({
    contact: YUP.string().required("Please Enter username"),
    password: YUP.string().required("Please Enter Password"),
  });
  return (
    <Containerbanner>
      <Navbar />
      <Container>
        <OuterContainer>
          <Brand>
            {" "}
            <LogoImage src={logoImg} alt="Logo Image" />
          </Brand>

          <FormContainer>
            <Formik
              initialValues={{
                contact: "",
                password: "",
              }}
              validationSchema={signInSchema}
              onSubmit={(values, { resetForm }) => {
                console.log("in submit login");
                console.log(values);
                setLoading(true);
                login(dispatch, values).then(() => {
                  setLoading(false);
                  setInputError(error);
                });

                // setLoading(false);

                resetForm(); //reset inputs after submit
              }}
            >
              {() => {
                return (
                  <Form>
                    <div className="form-group">
                      <Input
                        type="text"
                        placeholder="Email or Phone Number*"
                        className="form-control"
                        id="contact"
                        name="contact"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          borderBottom: "2px solid #898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                        }}
                      />
                      <Error>
                        <ErrorMessage name="email" />
                      </Error>
                    </div>
                    <div className="form-group">
                      <Input
                        type="text"
                        placeholder="Password*"
                        className="form-control"
                        id="password"
                        name="password"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Agapey",
                          letterSpacing: "0",
                          wordSpacing: "1.4px",
                          border: "none",
                          borderBottom: "2px solid #898C99",
                          borderRadius: "0px",
                          boxShadow: "none",
                          padding: "0",
                        }}
                      />
                      <Error>
                        <ErrorMessage name="password" />
                      </Error>
                    </div>
                    <Forget className="text-end">
                      <Link
                        to="/forget"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Para>Forget Password?</Para>
                      </Link>
                    </Forget>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {loading && (
                        <>
                          <TailSpin
                            type="Bars"
                            color="#adb4ec"
                            height={30}
                            width={30}
                          />
                        </>
                      )}
                    </div>
                    <div className="text-center">
                      {inputError && (
                        <Invalid>Invalid UserName/Password</Invalid>
                      )}
                      <Button type="submit">LOGIN</Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </FormContainer>
          <FormContainer>
            <div
              className="text-center"
              style={{
                fontSize: "14px",
                fontFamily: "Agapey",
                letterSpacing: "1.7px",
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                padding: "15px",
                color: "rgb(0, 0, 0)",
              }}
            >
              Create an account to take advantages of personalized services.
            </div>
            <div className="text-center">
              <Link
                to="/register"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button>CREATE AN ACCOUNT</Button>
              </Link>
            </div>
          </FormContainer>
        </OuterContainer>
      </Container>
    </Containerbanner>
  );
};

export default Login;
