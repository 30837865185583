import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AllProductList from "./pages/AllProduct";
import Product from "./pages/Product";
import Cart from "./pages/Cart";
import Login from "./pages/Login";
import Order from "./pages/Order";
import Success from "./pages/Success";
import Account from "./pages/Account";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Register from "./pages/Register";
import AdminHome from "./AdminPages/AdminHome";
import "@fontsource/arsenal";

function App() {
  const user = true;
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<AllProductList />} />
          <Route path="/product/:productId" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/account" element={user ? <Account /> : <Login />} />
          <Route path="/order" element={user ? <Order /> : <Login />} />
          {/* login register pages */}

          <Route path="/forget" element={<ForgetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/resetpassword/:id/:token" element={<ResetPassword />} />

          <Route path="/success" element={<Success />} />

          {/* Admin page Routes*/}
          <Route path="/adminhome" element={<AdminHome />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
