import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useCallback, useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import { publicRequest } from "../axiosMethod";
import Footer from "../components/Footer";
import LowerAnnouncement from "../components/LowerAnnouncement";
import Navbar from "../components/Navbar";
import UpperAnnouncement from "../components/UpperAnnouncement";
import { shades } from "../data";
import { large, medium } from "../responsive";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "../components/swiper.css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination } from "swiper/modules";

//styled comps

const MainContainer = styled.div`
  background-color: whitesmoke;
  transition: opacity 0.5s ease-in-out;
`;
const Container = styled.div`
  margin: 2rem 8rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  ${large({ margin: "2rem 1rem" })}
  ${medium({ flexDirection: "column", alignItems: "center" })}
`;

const ImageContainer = styled.div`
  padding: 10px;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 5px;
`;
const DetailContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 1px;
`;
const ProductName = styled.div``;
const ProductBrand = styled.div``;
const ProductPrice = styled.div``;
const ProductDesc = styled.div``;
const ShadesContainer = styled.div``;
const Shades = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
const ShadeSingle = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  background-color: ${(props) => props.hexValue};
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
  padding: 2px;
  transform: ${(props) =>
    props.shade === props.hexValue ? "scale(1.3)" : " "};
  border-radius: ${(props) => (props.shade === props.hexValue ? "10%" : " ")};
`;
const CartContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 1rem;
`;
const QuantityContainer = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 1.5rem 0;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  border: 2px solid var(--bs-secondary-color);
`;
const Button = styled.div`
  border: none;
  padding: 10px;
  // border-radius: 50px;
  // background-color: white;
  margin: 5px 10px 5px 10px;
  cursor: pointer;
  transition: 0.7s linear ease-in;
  &:focus {
    background: #38a536;
  }
`;

const AddToCartContainer = styled.div`
  margin: 1.5rem 0;
`;
const SizeSelectbutton = styled.button`
  background-color: transparent;
  font-weight: 200;
  border-radius: 7px;

  border: none;
  cursor: pointer;
  height: 100%;
`;
const CartButton = styled.div`
  // background-color: #4e4a4a;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  color: var(--bs-black);
  font-weight: 600;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  border: none;
  cursor: pointer;
  height: 100%;
  border: 2px solid var(--bs-secondary-color);
`;

const Product = () => {
  const id = useParams().productId;
  //states
  let [product, setProduct] = useState({});
  console.log("product details", product);
  const [loading, setLoading] = useState(true);
  let [shadeIn, setShade] = React.useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [sizeError, setSizeError] = useState(false);
  let [quantity, setQuantity] = React.useState(1);

  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;
    if (scrollY > 0 && !scrollingUp) {
      setScrollingUp(true);
    } else if (scrollY === 0 && scrollingUp) {
      setScrollingUp(false);
    }
  }, [scrollingUp]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const getProduct = async (id) => {
    try {
      console.log("in product");
      //id getting from url params ;
      let res = await publicRequest.get(`/products/${id}`);
      setProduct(res.data[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct(id);
  }, [id]);

  //handleshade
  const handleShade = (value) => {
    setShade(value);
  };

  const handleSizeSelection = (sizeId) => {
    setSelectedSize(sizeId);
    setSizeError(false); // Reset size selection error
    // Add further logic as needed based on the selected size
  };

  //inc or dec
  const handleQuantity = (value) => {
    if (value === "inc") {
      setQuantity(quantity + 1);
    } else {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    }
  };

  //add cart
  const handleCart = () => {
    console.log(product);
    // let product_colors = shadeIn;
    let variants = product.size;
    let cartProduct = { ...product, quantity, variants };
    console.log(cartProduct);
    console.log(cartProduct.variants.length);
    if (cartProduct.variants.length > 0) {
      if (cartProduct.variants.length > 0) {
        setAlert(true);
        dispatch({ type: "addProduct", payload: cartProduct });
        setError(false);
      } else {
        setError(true);
        setSizeError(true);
      }
    } else {
      setSizeError(true);
    }
  };

  return (
    <>
      {alert && (
        <ToastContainer position="center">
          <Toast
            bg={"success"}
            style={{
              width: "100%",
              height: "3rem",
              position: "fixed",
              top: "2.8%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.2rem",
              fontWeight: "900",
              zIndex: "10",
            }}
            onClose={() => setAlert(false)}
            show={alert}
            delay={300000}
            autohide
          >
            Your item is added to cart
          </Toast>
        </ToastContainer>
      )}
      <MainContainer>
        <UpperAnnouncement />
        <Navbar />
        <LowerAnnouncement />
        <Container>
          {loading ? (
            <div className="d-flex justify-content-center m-5">
              <TailSpin
                type="TailSpin"
                color="#25283D"
                height={100}
                width={100}
              />
            </div>
          ) : (
            <>
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img
                    src={`${process.env.REACT_APP_BASE_IMG_URL}${product.main_image}`}
                    alt="product"
                    style={{ objectFit: "cover" }}
                    // style={{ objectFit: "contain" }}
                    width="100%"
                    height="350px"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={`${process.env.REACT_APP_BASE_IMG_URL}${product.image_url}`}
                    alt="product"
                    style={{ objectFit: "cover" }}
                    // style={{ objectFit: "contain" }}
                    width="100%"
                    height="350px"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={`${process.env.REACT_APP_BASE_IMG_URL}${product.logo}`}
                    alt="product"
                    style={{ objectFit: "cover" }}
                    // style={{ objectFit: "contain" }}
                    width="100%"
                    height="350px"
                  />
                </SwiperSlide>
              </Swiper>
              {/* <ImageContainer>
                <img
                  src={`http://93.127.195.84:4110/image/${product.image_link}`}
                  alt="product"
                  style={{ objectFit: "cover" }}
                  // style={{ objectFit: "contain" }}
                  width="100%"
                  height="350px"
                />
              </ImageContainer> */}
              <DetailContainer>
                <ProductName>
                  <h2>{product.product_name}</h2>
                </ProductName>
                <ProductBrand>
                  <p>{product.brand_name}</p>
                </ProductBrand>
                <ProductPrice>
                  <h2>₹ {product.price}</h2>
                </ProductPrice>
                <StarRatings
                  rating={product.rating}
                  starRatedColor="gold"
                  numberOfStars={5}
                  name="rating"
                  starDimension="20px"
                  starSpacing="2px"
                />
                <ProductDesc>
                  <p style={{ color: "grey" }}>{product.description}</p>
                </ProductDesc>

                {product.size && product.stock_quantity > 0 && (
                  <div>
                    <h5> Size</h5>
                    <div>
                      <SizeSelectbutton>{product.size}</SizeSelectbutton>
                    </div>
                    {sizeError && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Please select a size
                      </p>
                    )}
                  </div>
                )}
                <CartContainer>
                  <QuantityContainer>
                    <Button
                      onClick={() => {
                        handleQuantity("dec");
                      }}
                    >
                      <RemoveIcon />
                    </Button>
                    <h3 style={{ display: "inline-block" }}>{quantity}</h3>
                    <Button
                      onClick={() => {
                        handleQuantity("inc");
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </QuantityContainer>
                  <AddToCartContainer>
                    <CartButton onClick={handleCart}>Add To Cart</CartButton>
                  </AddToCartContainer>
                </CartContainer>
              </DetailContainer>
            </>
          )}
        </Container>
        <Footer />
      </MainContainer>
    </>
  );
};

export default Product;
